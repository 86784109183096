.AppTitle {
  font-family: var(--font-base);
  font-style: normal;
  font-weight: var(--fw-b);
  font-size: var(--fs-d-lg);
  line-height: 1.3;
  color: var(--color-black);
  word-break: break-word;

  @media (max-width: 1440px) {
    font-size: var(--fs-d-md);
  }
}
