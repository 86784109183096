.AppSlider {
  display: block;
  position: relative;
  overflow: hidden;
  width: 540px;
  @media (max-width: 768px) {
    display: none;
  }
  &__Items {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease;
  }
  &__Item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.5s ease;
    &--Active {
      position: relative;
      opacity: 1;
    }
  }
  &__ItemImage {
    object-fit: cover;
  }
  &__Link {
    font-size: 24px;
    font-weight: 600;

    @media (max-width: 768px) {
      font-size: 20px;
      position: absolute;
      z-index: 10;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &--Active {
      position: relative;
      opacity: 1;
      z-index: 99;
    }
  }
  &__Contorls {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -6px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.5s;
  }
  &__ContorlsPrev {
    left: 16px;
  }
  &__ContorlsNext {
    right: 16px;
  }
}
