.AppGetInTouch {
  padding: 96px 0;

  @media (max-width: 1680px) {
    padding: 85px 0;
  }

  @media (max-width: 1440px) {
    padding: 80px 0;
  }

  @media (max-width: 1366px) {
    padding: 75px 0;
  }

  @media (max-width: 1280px) {
    padding: 70px 0;
  }

  @media (max-width: 1024px) {
    padding: 65px 0;
  }

  @media (max-width: 768px) {
    padding: 30px 0;
  }

  &__Wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    margin-bottom: 64px;

    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
      margin-bottom: 32px;
    }

    @media (max-width: 768px) {
      gap: 20px;
      margin-bottom: 20px;
    }

    .AppGetInTouch__Content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 48px;
      max-width: 560px;

      @media (max-width: 1024px) {
        margin: 0 auto;
        align-items: center;
      }

      &__Illustration {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 50px;

        @media (max-width: 1024px) {
          align-items: center;
        }

        &__Swiper {
          display: flex;
          width: 540px;
          height: auto;
          min-height: 400px;
          @media (max-width: 768px) {
            display: none;
          }

          // @media (max-width: 568px) {
          //   width: 280px;
          // }

          figure {
            height: 500px;
            margin: 20px 20px 40px 20px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain !important;
            }

            @media (max-width: 1440px) {
              height: 450px;
            }

            @media (max-width: 1366px) {
              height: 400px;
            }

            @media (max-width: 1280px) {
              height: 300px;
            }

            @media (max-width: 1024px) {
              height: 250px;
            }
          }

          @media (max-width: 1440px) {
            min-height: 300px;
          }
        }

        &__MobileSwiper {
          display: flex;
          width: 540px;
          height: auto;
          min-height: 400px;
          display: none;
          @media (max-width: 768px) {
            display: block;
            width: 280px;
          }

          figure {
            height: 500px;
            margin: 20px 20px 40px 20px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain !important;
            }

            @media (max-width: 1440px) {
              height: 450px;
            }

            @media (max-width: 1366px) {
              height: 400px;
            }

            @media (max-width: 1280px) {
              height: 300px;
            }

            @media (max-width: 1024px) {
              height: 250px;
            }
          }

          @media (max-width: 1440px) {
            min-height: 300px;
          }
        }

        &__Desc {
          text-align: center;
          font-family: var(--font-base);
          font-style: normal;
          font-weight: var(--fw-sb);
          font-size: var(--fs-d-xs);
          line-height: 1.3;
          color: var(--color-black);
          span {
            font-family: var(--font-base);
            font-style: normal;
            font-weight: var(--fw-sb);
            font-size: var(--fs-d-xs);
            line-height: 1.3;
            color: var(--color-black);
          }
        }
      }
    }
  }
  &__Button {
    text-align: start;
  }
  &__Link {
    font-size: 24px;
    font-weight: 600;

    @media (max-width: 768px) {
      font-size: 20px;
      position: absolute;
      z-index: 10;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

.AppGetInTouchNavigation {
  position: absolute;
  bottom: -4px;
  display: flex;
  justify-content: space-between;
  margin-left: 50%;
  transform: transalte(-50%, 4px);
  width: 100%;
  z-index: 1000;
  @media (max-width: 1440px) {
    width: 90%;
  }

  @media (max-width: 1366px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    bottom: 28px;
  }

  .AppGetInTouchNavigationNext,
  .AppGetInTouchNavigationPrev {
    cursor: pointer;
    z-index: 1000;
    padding: 8px 12px;
  }
}
